<template>
  <div>
    <!-- Filter Section -->
    <FilterReport title="Filters" :baseReportUrl="baseReportUrl" @clicked-filter="getReport" />

    <div class="mt-1 mb-1 d-flex justify-content-end">

      <b-button variant="outline-primary" @click="print">
        <feather-icon icon="FileIcon" />
        {{ $t('globalActions.print') }}
      </b-button>

      <b-button-group class="ml-1">
        <b-dropdown right variant="outline-primary">

          <template #button-content>
            <feather-icon icon="DownloadIcon" />
            {{ $t('globalActions.export') }}
          </template>
           <b-dropdown-item-button @click="exportToExcel">Excel</b-dropdown-item-button>

        </b-dropdown>
      </b-button-group>

    </div>

    <!-- Table Container Card -->
    <b-card no-body id="pdfDom" v-if="periodeTypeText === 'daily'">
      <b-card-body class="text-center">
        <h2>{{ companyName }}</h2>
        <h3>{{ $t('apps.reportEstimationCollection.singular.payment') }}</h3>
        <h4 v-if="officeReportText" class="mt-sm-1">{{ officeReportText }}</h4>
        <h4 class="mt-sm-1">{{ periodReportText }}</h4>
      </b-card-body>
      <b-table-simple id="balance-sheet-table">
        <b-thead>
          <b-tr>
            <b-th v-for="column in tableColumnsDaily" :key="column.key">{{ column.label }}</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(prop, index) in itemLists" :key="prop.number">
            <b-td v-for="column in tableColumnsDaily" :key="`${column.key}-${index}`" :class="column.tdClass">{{
                prop[column.key]
            }}</b-td>
          </b-tr>
        </b-tbody>
        <b-tbody>
          <b-tr>
            <b-td class="text-left font-weight-bolder font-italic" colspan="3">Total</b-td>
            <b-td class="text-left font-weight-bolder font-italic">{{ total.totalAmount }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
    <!-- Table Container Card -->
    <b-card no-body id="pdfDom" v-else-if="periodeTypeText === 'week'">
      <b-card-body class="text-center">
        <h2>{{ companyName }}</h2>
        <h3>{{ $t('apps.reportEstimationCollection.singular.payment') }}</h3>
        <h4 v-if="officeReportText" class="mt-sm-1">{{ officeReportText }}</h4>
        <h4 class="mt-sm-1">{{ periodReportText }}</h4>
      </b-card-body>
      <b-table-simple id="balance-sheet-table">
        <b-thead>
          <b-tr>
            <b-th v-for="column in tableColumnsMonth" :key="column.key">{{ column.label }}</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(prop, index) in itemLists" :key="prop.number">
            <b-td v-for="column in tableColumnsMonth" :key="`${column.key}-${index}`" :class="column.tdClass">{{
                prop[column.key]
            }}</b-td>
          </b-tr>
        </b-tbody>
        <b-tbody>
          <b-tr>
            <b-td class="text-left font-weight-bolder font-italic" colspan="2">Total</b-td>
            <b-td class="text-left font-weight-bolder font-italic">{{ total.totalWeek_1 }}</b-td>
            <b-td class="text-left font-weight-bolder font-italic">{{ total.totalWeek_2 }}</b-td>
            <b-td class="text-left font-weight-bolder font-italic">{{ total.totalWeek_3 }}</b-td>
            <b-td class="text-left font-weight-bolder font-italic">{{ total.totalWeek_4 }}</b-td>
            <b-td class="text-left font-weight-bolder font-italic">{{ total.totalWeek_5 }}</b-td>
            <b-td class="text-left font-weight-bolder font-italic">{{ total.totalAmount }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
    <!-- Table Container Card -->
    <b-card no-body id="pdfDom" v-else-if="periodeTypeText === 'month'">
      <b-card-body class="text-center">
        <h2>{{ companyName }}</h2>
        <h3>{{ $t('apps.reportEstimationCollection.singular.payment') }}</h3>
        <h4 v-if="officeReportText" class="mt-sm-1">{{ officeReportText }}</h4>
        <h4 class="mt-sm-1">{{ periodReportText }}</h4>
      </b-card-body>
      <b-table-simple id="balance-sheet-table" responsive>
        <b-thead>
          <b-tr>
            <b-th v-for="column in tableColumnsYear" :key="column.key">{{ column.label }}</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(prop, index) in itemLists" :key="prop.number">
            <b-td v-for="column in tableColumnsYear" :key="`${column.key}-${index}`" :class="column.tdClass">{{
                prop[column.key]
            }}</b-td>
          </b-tr>
        </b-tbody>
        <b-tbody>
          <b-tr>
            <b-td class="text-left font-weight-bolder font-italic" colspan="2">Total</b-td>
            <b-td class="text-left font-weight-bolder font-italic">{{ total.totalJan }}</b-td>
            <b-td class="text-left font-weight-bolder font-italic">{{ total.totalFeb }}</b-td>
            <b-td class="text-left font-weight-bolder font-italic">{{ total.totalMar }}</b-td>
            <b-td class="text-left font-weight-bolder font-italic">{{ total.totalApr }}</b-td>
            <b-td class="text-left font-weight-bolder font-italic">{{ total.totalMei }}</b-td>
            <b-td class="text-left font-weight-bolder font-italic">{{ total.totalJun }}</b-td>
            <b-td class="text-left font-weight-bolder font-italic">{{ total.totalJul }}</b-td>
            <b-td class="text-left font-weight-bolder font-italic">{{ total.totalAgs }}</b-td>
            <b-td class="text-left font-weight-bolder font-italic">{{ total.totalSep }}</b-td>
            <b-td class="text-left font-weight-bolder font-italic">{{ total.totalOkt }}</b-td>
            <b-td class="text-left font-weight-bolder font-italic">{{ total.totalNov }}</b-td>
            <b-td class="text-left font-weight-bolder font-italic">{{ total.totalDes }}</b-td>
            <b-td class="text-left font-weight-bolder font-italic">{{ total.totalAmount }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BButtonGroup,
  BButton,
  BDropdown,
  BTableSimple,
  BThead,
  BTh,
  BTr,
  BTd,
  BTbody,
  BDropdownItemButton
} from 'bootstrap-vue'
import FilterReport from '@/components/reports/FilterReportPeriode.vue'

import { computed } from '@vue/composition-api'
import { formatCurrency } from '@/utils/formatter'
import useListTable from '@/comp-functions/useListTable'
import useFilterReport from '@/comp-functions/useFilterReport'
import { required } from '@validations'

export default {
  components: {
    BCard,
    BCardBody,
    BButtonGroup,
    BButton,
    BDropdown,
    BTableSimple,
    BThead,
    BTh,
    BTr,
    BTd,
    BTbody,
    FilterReport,
    BDropdownItemButton
  },
  computed: {
    companyName() {
      return this.$store.state.user.currentCompany.name
    }
  },
  setup() {
    // export format
    const formats = ['xlsx', 'csv', 'txt']

    // Table Handlers
    const tableColumnsDaily = [
      { key: 'number', label: 'No', thClass: '', tdClass: '' },
      { key: 'date', label: 'Tanggal Estimasi Collection', thClass: '', tdClass: '' },
      { key: 'customer_name', label: 'Nama Customer', thClass: 'text-left', tdClass: 'text-left' },
      { key: 'amount_view', label: 'Amount', thClass: 'text-left', tdClass: 'text-left' }
    ]

    const tableColumnsMonth = [
      { key: 'number', label: 'No', thClass: '', tdClass: '' },
      { key: 'customer_name', label: 'Estimasi Collection', thClass: '', tdClass: '' },
      { key: 'week_1_view', label: 'Week 1', thClass: 'text-left', tdClass: 'text-left' },
      { key: 'week_2_view', label: 'Week 2', thClass: 'text-left', tdClass: 'text-left' },
      { key: 'week_3_view', label: 'Week 3', thClass: 'text-left', tdClass: 'text-left' },
      { key: 'week_4_view', label: 'Week 4', thClass: 'text-left', tdClass: 'text-left' },
      { key: 'week_5_view', label: 'Week 5', thClass: 'text-left', tdClass: 'text-left' },
      { key: 'amount_view', label: 'Amount', thClass: 'text-left', tdClass: 'text-left' },
    ]

    const tableColumnsYear = [
      { key: 'number', label: 'No', thClass: '', tdClass: '' },
      { key: 'customer_name', label: 'Estimasi Collection', thClass: '', tdClass: '' },
      { key: 'jan_view', label: 'Jan', thClass: 'text-left', tdClass: 'text-left' },
      { key: 'feb_view', label: 'Feb', thClass: 'text-left', tdClass: 'text-left' },
      { key: 'mar_view', label: 'Mar', thClass: 'text-left', tdClass: 'text-left' },
      { key: 'apr_view', label: 'Apr', thClass: 'text-left', tdClass: 'text-left' },
      { key: 'mei_view', label: 'Mei', thClass: 'text-left', tdClass: 'text-left' },
      { key: 'jun_view', label: 'Jun', thClass: 'text-left', tdClass: 'text-left' },
      { key: 'jul_view', label: 'Jul', thClass: 'text-left', tdClass: 'text-left' },
      { key: 'ags_view', label: 'Ags', thClass: 'text-left', tdClass: 'text-left' },
      { key: 'sep_view', label: 'Sep', thClass: 'text-left', tdClass: 'text-left' },
      { key: 'okt_view', label: 'Okt', thClass: 'text-left', tdClass: 'text-left' },
      { key: 'nov_view', label: 'Nov', thClass: 'text-left', tdClass: 'text-left' },
      { key: 'des_view', label: 'Des', thClass: 'text-left', tdClass: 'text-left' },
      { key: 'amount_view', label: 'Amount', thClass: 'text-left', tdClass: 'text-left' },
    ]

    // Table Handlers
    const columnToBeFilter = [
      'code',
      'name',
      'brand'
    ]

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })
    const total = computed(() => {
      //daily
      const totalAmount = formatCurrency(itemLists.value.reduce((accumulator, { amount }) => accumulator + Number(amount), 0))
      //month
      const totalWeek_1 = formatCurrency(itemLists.value.reduce((accumulator, { week_1 }) => accumulator + Number(week_1), 0))
      const totalWeek_2 = formatCurrency(itemLists.value.reduce((accumulator, { week_2 }) => accumulator + Number(week_2), 0))
      const totalWeek_3 = formatCurrency(itemLists.value.reduce((accumulator, { week_3 }) => accumulator + Number(week_3), 0))
      const totalWeek_4 = formatCurrency(itemLists.value.reduce((accumulator, { week_4 }) => accumulator + Number(week_4), 0))
      const totalWeek_5 = formatCurrency(itemLists.value.reduce((accumulator, { week_5 }) => accumulator + Number(week_5), 0))
      //year
      const totalJan = formatCurrency(itemLists.value.reduce((accumulator, { jan }) => accumulator + Number(jan), 0))
      const totalFeb = formatCurrency(itemLists.value.reduce((accumulator, { feb }) => accumulator + Number(feb), 0))
      const totalMar = formatCurrency(itemLists.value.reduce((accumulator, { mar }) => accumulator + Number(mar), 0))
      const totalApr = formatCurrency(itemLists.value.reduce((accumulator, { apr }) => accumulator + Number(apr), 0))
      const totalMei = formatCurrency(itemLists.value.reduce((accumulator, { mei }) => accumulator + Number(mei), 0))
      const totalJun = formatCurrency(itemLists.value.reduce((accumulator, { jun }) => accumulator + Number(jun), 0))
      const totalJul = formatCurrency(itemLists.value.reduce((accumulator, { jul }) => accumulator + Number(jul), 0))
      const totalAgs = formatCurrency(itemLists.value.reduce((accumulator, { ags }) => accumulator + Number(ags), 0))
      const totalSep = formatCurrency(itemLists.value.reduce((accumulator, { sep }) => accumulator + Number(sep), 0))
      const totalOkt = formatCurrency(itemLists.value.reduce((accumulator, { okt }) => accumulator + Number(okt), 0))
      const totalNov = formatCurrency(itemLists.value.reduce((accumulator, { nov }) => accumulator + Number(nov), 0))
      const totalDes = formatCurrency(itemLists.value.reduce((accumulator, { des }) => accumulator + Number(des), 0))
      return { totalAmount, totalWeek_1, totalWeek_2, totalWeek_3, totalWeek_4, totalWeek_5, totalJan, totalFeb, totalMar, totalApr, totalMei, totalJun, totalJul, totalAgs, totalSep, totalOkt, totalNov, totalDes }

    })

    return {
      total,
      formatCurrency,
      formats,
      tableColumnsDaily,
      tableColumnsMonth,
      tableColumnsYear,
      columnToBeFilter,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      required,
      ...useFilterReport({
        baseUrl: 'reports/estimation-collection',
        fetchLists
      })
    }
  },
  methods: {
    print() {
      window.print()
    },
    exportToExcel () {
      import('@/vendors/Export2Excel').then(excel => {
        let list = this.itemLists

        if(this.periodeTypeText == 'daily'){
          this.tableColumns = this.tableColumnsDaily
        }

        if(this.periodeTypeText == 'month'){
          this.tableColumns = this.tableColumnsYear
        }


        if(this.periodeTypeText == 'week'){
          this.tableColumns = this.tableColumnsMonth
        }

        // add title
        // const title = [this.$store.state.user.currentCompany.name, this.$t('apps.reportLedger.singular.ledgerReport'), this.periodReportText]
        const headerTitle = this.tableColumns.map(column => column.label)
        // only take indexData from columnDef,
        const headerVal = this.tableColumns.map(column => column.key)
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          multiHeader: [
            [this.$store.state.user.currentCompany.name, '', '', '', '', '', ''],
            ['Report Estimation Payment', '', '', '', '', '', ''],
            [this.periodReportText, '', '', '', '', '', ''],
            ['', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 6, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 6, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 6, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 6, r: 3 } }
          ],
          filename: `Report Estimation Payment ${this.periodReportText}`,
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  }
}
</script>

<style lang="scss">
.header-category-account {
  font-weight: 600;
  font-size: 1rem;
}

.child-account {
  padding-left: 48px !important;
  font-size: 0.9rem;
}

.total-category-account {
  font-weight: 600;
  font-size: 0.9rem;
  padding-left: 40px !important;
}

.total-top-category-account {
  font-weight: 600;
  font-size: 1rem;
  font-style: italic;
}

#balance-sheet-table {
  width: inherit;
  margin: 0 10px 20px 10px;

  thead {
    th {
      border-top: 2px solid #ebe9f1;
    }

    th:first-child {
      border-left: 2px solid #ebe9f1;
      border-radius: 4px;
    }

    th:last-child {
      border-right: 2px solid #ebe9f1;
      border-radius: 4px;
    }
  }

  td:first-child {
    border-left: 2px solid #ebe9f1;
  }

  td:last-child {
    border-right: 2px solid #ebe9f1;
  }

  tbody {
    tr:last-child {
      border-bottom: 2px solid #ebe9f1;
    }
  }

}

@media print {
  #app {
    * {
      visibility: hidden;
      margin: 0 !important;
    }

    #pdfDom,
    #pdfDom * {
      visibility: visible;
    }

    #pdfDom {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      margin-top: 100px;
    }
  }
}

@page {
  size: auto;
}
</style>
